<template>
	<el-dialog :close-on-click-modal="false" width="900px" title="录入证照" center @close="handleClose" :visible.sync="dialogVisible">
		<el-form :model="params" ref="params" :rules="rules" :label-position="labelPosition" label-width="140px" style="margin: 0 60px">
			<el-row>
				<el-col :span="12" v-if="params.type == '2'">
					<el-form-item class="selectStyle" label="姓名" prop="staffId" style="font-weight: normal">
						<el-select v-model="params.staffId" filterable placeholder="请选择" :disabled="['add', 'edit'].includes(dialogType)">
							<el-option
								v-for="item in staffList"
								:key="item.staffId"
								:label="item.staffName"
								:value="item.staffId"
								style="height: 60px"
							>
								<div style="display: flex; margin-top: 7px">
									<el-avatar :size="50" :src="item.avatarUrl"></el-avatar>
									<div style="margin-left: 10px; line-height: normal; margin-top: 5px">
										<label>{{ item.staffName }}({{ item.jobNumber }})</label>
										<br />
										<span>职位：{{ item.officeName }}</span>
									</div>
								</div>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item class="selectStyle" label="证照类型" :span="12" prop="cerType">
						<el-select style="width: 100%" v-model="params.cerType" @change="certypeChange" :disabled="['edit'].includes(dialogType)">
							<el-option v-for="(item, index) in licenseCerList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="cerType === '1'">
					<el-form-item label="身份证（人像面）" prop="frontOfIdCard">
						<uploadImage v-model="params.frontOfIdCard" @change="(val) => uploadChange(val, 'frontOfIdCard')" :limit="1" />
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="cerType === '1'">
					<el-form-item label="身份证（国徽面）" prop="reverseOfIdCard">
						<uploadImage v-model="params.reverseOfIdCard" @change="(val) => uploadChange(val, 'reverseOfIdCard')" :limit="1" />
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="cerType === '2'">
					<el-form-item label="学历证书" prop="eduHisCertificate">
						<uploadImage v-model="params.eduHisCertificate" @change="(val) => uploadChange(val, 'eduHisCertificate')" />
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="cerType === '2'">
					<el-form-item label="学位证书" prop="degreeCertificate">
						<uploadImage v-model="params.degreeCertificate" @change="(val) => uploadChange(val, 'degreeCertificate')" />
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="cerType === '3'">
					<el-form-item label="证件照" prop="idPhoto">
						<uploadImage v-model="params.idPhoto" @change="(val) => uploadChange(val, 'idPhoto')" :limit="1" />
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="cerType === '3'">
					<el-form-item label="生活照" prop="lifePhoto">
						<uploadImage v-model="params.lifePhoto" @change="(val) => uploadChange(val, 'lifePhoto')" :limit="1" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="语种" prop="language" v-if="cerType === '5'">
						<el-input v-model="params.language"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="证书名称" prop="licenseName" v-if="['4', '5'].includes(cerType)">
						<el-input v-model="params.licenseName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="等级" prop="level" v-if="cerType === '5'">
						<el-input v-model="params.level"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="证书编号" prop="licenseId" v-if="['4', '5'].includes(cerType)">
						<el-input v-model="params.licenseId"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="分数" prop="score" v-if="['4', '5'].includes(cerType)">
						<el-input v-model="params.score"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="颁发时间" prop="issueDate" v-if="cerType === '4'">
						<el-input v-model="params.issueDate"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item :label="getTitleByCerType" prop="licenseUrl" v-if="['4', '5', '6', '7'].includes(cerType)">
						<uploadImage v-model="params.licenseUrl" @change="(val) => uploadChange(val, 'licenseUrl')" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" :loading="isUpload" @click="saveLicencet">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import * as api from '@/api/workbench';
import uploadImage from '@/views/components/components-upload/index.vue';
// import treeselect from '@/components/treeSelect/treeSelect';

export default {
	components: {
		uploadImage
		// treeselect
	},

	props: {
		show: {
			type: Boolean,
			default: false
		},
		companyShow: {
			type: Boolean,
			default: false
		},
		licenseParams: {},
		dialogType: {
			type: String
		}
	},
	computed: {
		cerType() {
			return this.params.cerType;
		},
		getTitleByCerType() {
			let str = '证照图片';

			switch (this.cerType) {
				case '7':
					str = '材料图片';
					break;
				case '6':
					str = '证明图片';
					break;

				default:
					break;
			}
			return str;
		},
		getUrlByApi() {
			if (this.$route.query.subType === '20') {
				return (apiStr) => api[`${apiStr}Temp`];
			}
			return (apiStr) => api[apiStr];
		}
	},
	watch: {
		show() {
			this.dialogVisible = this.show;
		},
		companyShow() {
			this.dialogVisible = this.companyShow;
		},
		licenseParams(newVal) {
			this.params = newVal;
			if (this.params.type == 2) {
				this.licenseCerList = this.$store.state.app.dict.filter((item) => item.groupId == 'licenseCerType');
			} else {
				this.licenseCerList = this.$store.state.app.dict.filter((item) => item.groupId == 'licenseCerTypeByCompany');
			}
		},
		'licenseParams.staffId': {
			handler(val) {
				this.params.staffId = val;
			}
		}
	},

	data() {
		return {
			isUpload: false,
			// percentage:0,
			isClearable: true, // 可清空（可选）
			isAccordion: true, // 可收起（可选）
			dialogVisible: this.show,
			staffList: [],
			licenseCerList: [],
			labelPosition: 'right',
			previewVisible: false,
			uploadDone: true,
			params: {},
			depsId: '',
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			deps: this.$store.state.setData.depsList,
			type: 0,
			rules: {
				cerType: [{ required: true, message: '请选择证照类型', trigger: 'change' }],
				staffId: [{ required: true, message: '请选择', trigger: 'change' }],
				frontOfIdCard: [{ required: true, message: '请上传', trigger: 'change' }],
				reverseOfIdCard: [{ required: true, message: '请上传', trigger: 'change' }],
				eduHisCertificate: [{ required: true, message: '请上传', trigger: 'change' }],
				language: [{ required: true, message: '请选择', trigger: 'change' }],
				licenseName: [{ required: true, message: '请输入', trigger: 'change' }]
				// degreeCertificate:  [{ required: true, message: '请上传', trigger: 'change' }],
			}
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.staffList = this.$store.state.setData.principalList;
			this.params = { ...this.licenseParams };
			if (this.params.type == 2) {
				this.licenseCerList = this.$store.state.app.dict.filter((item) => item.groupId == 'licenseCerType');
			} else {
				this.licenseCerList = this.$store.state.app.dict.filter((item) => item.groupId == 'licenseCerTypeByCompany');
			}
		});
	},
	methods: {
		// 录入
		saveLicencet() {
			this.$refs['params'].validate((valid) => {
				if (valid) {
					if (this.uploadDone) {
						this.handleSaveLicencet();
					} else {
						this.$message.warning('图片正在上传中，请稍后提交');
					}
				} else {
					return false;
				}
			});
		},
		handleClose() {
			this.$refs['params'].validateField();
			this.$refs['params'].resetFields();
			this.params = { ...this.licenseParams, licenseUrl: '', cerType: '' };
			this.$emit('update:show', false);
		},
		certypeChange(e) {
			this.params = {
				...this.licenseParams,
				staffId: this.params.staffId,
				cerType: e,
				licenseUrl: ''
			};
		},
		uploadChange(val, key) {
			// this.params[key] = val;
			this.$set(this.params, key, val);
			this.$refs['params'].validateField(key);
		},
		handleSaveLicencet() {
			const params = {
				request: { ...this.params }
			};

			const checkKeys = ['frontOfIdCard', 'reverseOfIdCard', 'eduHisCertificate', 'degreeCertificate', 'idPhoto', 'lifePhoto', 'licenseUrl'];

			checkKeys.forEach((key) => {
				if (this.params[key]?.length) {
					params.request[key] = this.params[key].map(({ url }) => url).join('|');
				} else {
					params.request[key] = '';
				}
			});

			this.isUpload = true;
			this.getUrlByApi('saveLicencet')(params)
				.then((result) => {
					if (result.code == 0) {
						if (this.isUpdate) {
							this.$emit('handleUpdate');
						} else if (this.licenseParams.type == 2) {
							this.$emit('handleSearch');
						} else if (this.licenseParams.type == 1) {
							this.$emit('handleSearch');
						}
						this.$refs['params'].validateField();
						this.$refs['params'].resetFields();
						this.params = { ...this.licenseParams };
						this.$message.success('保存成功');
					}
					this.isUpload = false;
				})
				.catch(() => {
					this.isUpload = false;
				});
		}
	}
};
</script>
<style lang="scss">
.uploadBox {
	display: inline-block;
	.el-upload-list__item {
		width: 80px !important;
		height: 80px !important;
	}
	.el-upload--picture-card {
		width: 80px;
		height: 80px;
		.el-icon-plus {
			vertical-align: top;
			margin-top: 25px;
		}
	}
}
.selectStyle {
	// .el-form-item__content {
	// 	width: 58%;
	// }
}
</style>
<style lang="scss" scoped></style>
