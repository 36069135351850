<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>

				<div class="title">{{ (imgInfo && imgInfo.name) || (imgInfo && imgInfo.companyName) }}-证照详情</div>
			</div>

			<div class="picbox" v-show="this.$route.query.type == 2">
				<div>
					<el-avatar :size="100" :src="imgInfo && imgInfo.avatarUrl"></el-avatar>
				</div>

				<div>
					<h2>{{ imgInfo && imgInfo.name }}</h2>

					<p>{{ imgInfo && imgInfo.orgName }}&nbsp;&nbsp;{{ imgInfo && imgInfo.jobName }}</p>

					<p>
						<span>手机号码：{{ imgInfo && imgInfo.phoneNumber }}</span>

						<span>入职时间：{{ imgInfo && imgInfo.entryTime }}</span>
					</p>
				</div>
			</div>

			<div class="viewImgBox">
				<el-collapse class="imgInfo" v-model="activeNames">
					<el-collapse-item name="1" class="imgInfo">
						<template slot="title">
							<div class="titleIcon"></div>
							证照记录
						</template>
						<div v-for="(item, index) in imgInfo.responseList" :key="index" style="margin-bottom: 10px">
							<div class="moreInfo">
								<label>证照{{ index + 1 }}</label>
								<div class="editBtn" style="display: inline-block" v-if="!isView">
									<el-button @click="handleEdit(JSON.stringify(item))" type="text" v-if="permissionControlBtns(pageName, 'Edit')"
										>编辑</el-button
									>
									<el-button
										@click="handleDel(item)"
										style="color: #f56c6c"
										type="text"
										v-if="permissionControlBtns(pageName, 'Delete')"
										>删除</el-button
									>
								</div>
							</div>
							<div class="baseInfo">
								<div>
									<div>
										<label class="left_label">证照类型</label>
										<label class="right_label">{{ item.cerTypeName }}</label>
									</div>
									<!-- <div>
                      <label class="left_label">证照名称</label>
                      <label class="right_label">{{item.licenseName}}</label>
                  </div>-->
									<div>
										<!-- <label class="left_label" v-if="item.cerType == '1'">身份证号码</label> -->
										<!-- <label class="left_label" v-if="item.cerType != '1'">证照编码</label> -->
										<label class="right_label">{{ item.licenseId }}</label>
									</div>
								</div>
								<div>
									<div>
										<label class="left_label">证照</label>
										<label class="right_label">
											<span
												@click="downloadModal({ url: img, bucketName: item.cerTypeName, name: item.cerTypeName, key: idx })"
												class="avatar"
												v-for="(img, idx) in imgSrcList(item)"
												:key="idx"
											>
												<img :src="img" />
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="addBtn" v-if="permissionControlBtns(pageName, 'Add') && !isView">
							<el-button icon="el-icon-plus" type="text" @click="handleOpen">添加证照信息</el-button>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<el-dialog :visible.sync="downLoadVisible" title="下载证照" center @close="downLoadVisible = false">
				<div id="imgWrap">
					<img id="imgFile" width="100%" :src="downLoadUrl" alt />
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="downLoad" type="primary">下载</el-button>
					<el-button type="primary" v-print="'#imgFile'">打印</el-button>
				</div>
			</el-dialog>
		</div>
		<!--<el-dialog title="提示" :visible.sync="dialogVisible" width="300px">
      <span>是否确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteRow">确 定</el-button>
      </span>
    </el-dialog>!-->
		<!-- <updatemodal :isUpdate="isUpdate" @handleUpdate="handleUpdate" :show.sync="show" :licenseParams="licenseParams"></updatemodal> -->
		<entryCertificateModal
			@handleSearch="handleSearch"
			:show.sync="showDialog"
			:licenseParams="licenseParams"
			:dialogType="dialogType"
		></entryCertificateModal>
	</div>
</template>
<script>
import * as api from '@/api/workbench';
// import updatemodal from '@/components/humanafairs/imageManage/updateModal';
import { downloadByCreateA, waterMark } from '@/utils/util';
import entryCertificateModal from './information/personnel/entryCertificateModal.vue';
export default {
	components: {
		// updatemodal,
		entryCertificateModal
	},

	data() {
		return {
			imgInfo: {
				default() {
					return {
						items: ''
					};
				}
			},
			licenseCerList: [],
			activeNames: ['1'],
			show: false,
			licenseParams: {
				id: '',
				staffId: '',
				orgId: '',
				type: '',
				cerType: '',
				licenseId: '',
				licenseName: '',
				licenseUrl: ''
			},
			dialogVisible: false,
			delParams: {},
			downLoadVisible: false,
			downLoadUrl: '',
			showDialog: false,
			dialogType: ''
		};
	},
	computed: {
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		imgSrcList() {
			return (item) =>
				[
					item.frontOfIdCard,
					item.reverseOfIdCard,
					item.eduHisCertificate,
					item.degreeCertificate,
					item.idPhoto,
					item.lifePhoto,
					item.licenseUrl
				]
					.filter((v) => v)
					.join('|')
					.split('|');
		},
		pageName() {
			return this.$route?.name;
		},
		isView() {
			return this.$route.query.index === '3';
		}
	},
	created() {},

	mounted() {
		this.licenseParams.type = this.$route.query.type;
		this.$nextTick(() => {
			// this.licenseParams.type = this.$route.query.type
			this.queryLicenceDetail();
			this.licenseCerList = this.$store.state.app.dict.filter((item) => item.groupId == 'licenseCerType');
		});
	},
	methods: {
		handleSearch() {
			this.queryLicenceDetail();
			this.showDialog = false;
		},
		handleOpen() {
			if (this.$route.query.type == 2) {
				this.licenseParams.staffId = Number(this.$route.query.id);
			} else if (this.$route.query.type == 1) {
				this.licenseParams.orgId = this.imgInfo.id;
			}
			this.licenseParams.id = '';
			this.licenseParams.cerType = ' ';
			this.licenseParams.licenseUrl = '';
			this.licenseParams.licenseId = '';
			this.licenseParams.fileUrlList = [];
			// this.show = true;
			this.dialogType = 'add';
			this.showDialog = true;
		},
		// 下载打印dialog
		downloadModal(img) {
			this.downLoadVisible = true;
			this.downLoadUrl = img.url;
			this.$nextTick(() => {
				// 加水印
				waterMark({ text: this.userInfo.nickName, container: document.querySelector('#imgWrap') });
			});
		},
		downLoad() {
			downloadByCreateA(this.downLoadUrl);
			// window.open(`/v1/common/downLoad?exportparam=${encodeURIComponent(JSON.stringify(this.downLoadParams))}`);
		},
		queryLicenceDetail() {
			api.queryLicenceDetail({
				request: {
					id: this.$route.query.id,
					type: this.$route.query.type
				}
			})
				.then((result) => {
					this.imgInfo = result;
				})
				.catch(() => {});
		},
		handleEdit(item) {
			this.licenseParams = JSON.parse(item);
			if (this.$route.query.type == 2) {
				this.licenseParams.staffId = Number(this.$route.query.id);
				const checkKeys = [
					'frontOfIdCard',
					'reverseOfIdCard',
					'eduHisCertificate',
					'degreeCertificate',
					'idPhoto',
					'lifePhoto',
					'licenseUrl'
				];

				checkKeys.forEach((key) => {
					if (this.licenseParams[key]) {
						this.licenseParams[key] = this.licenseParams[key].split('|').map((url) => ({ url, name: url }));
					}
				});
			} else if (this.$route.query.type == 1) {
				this.licenseParams.orgId = this.imgInfo.id;
			}
			// this.show = true;
			this.dialogType = 'edit';
			this.showDialog = true;
		},
		handleDel(item) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.delParams = item;
				this.deleteRow();
			});
		},
		deleteRow() {
			let params = {};

			if (this.$route.query.type == 2) {
				params = {
					request: {
						id: this.delParams.id,
						staffId: this.imgInfo.id
					}
				};
			} else if (this.$route.query.type == 1) {
				params = {
					request: {
						id: this.delParams.id,
						orgId: this.imgInfo.id
					}
				};
			}

			api.deleteLicence(params)
				.then((result) => {
					if (result.code == 0) {
						this.dialogVisible = false;
						this.queryLicenceDetail();
						this.$message.success('删除成功');
					}
				})
				.catch(() => {});
		},
		handleUpdate() {
			this.show = false;
			this.queryLicenceDetail();
		}
	}
};
</script>
<style lang="scss" scope>
.assessBox .app-container .viewImgBox .level_box_now {
	font-weight: bold;
}
.picbox {
	padding: 0px 0 0 100px;

	background: #fff;

	margin: 0 20px;

	display: flex;

	align-items: center;

	margin-bottom: 20px;

	height: 140px;

	:first-child {
		margin-right: 10px;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	p {
		margin: 15px 0px;

		color: #999999;
	}
}

.addBtn {
	border: 1px dashed #ccc;
	text-align: center;
	height: 40px;
	margin: 0px 15px;
	padding: 0px 0px;
	font-size: 16px;
}

.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewImgBox {
			margin: 0 20px;
			background: #ffffff;
			// min-height: 300px;
			padding: 30px 50px;
			.el-collapse-item__header {
				margin: 5px 0px 0px 0px;
				border-bottom: none;
				font-size: 16px;
				font-weight: bold;
				padding: 0px 15px;
				height: 75px;
			}
			.el-collapse-item__content {
				line-height: normal;
			}
			.baseInfo {
				text-align: center;

				font-size: 14px;

				.editBtn {
					text-align: right;

					padding-right: 25px;
				}

				div {
					min-height: 50px;

					div {
						width: calc(100% / 2);

						float: left;
					}

					.row_label {
						width: 100%;

						height: auto;

						margin-top: 10px;

						.left_label {
							width: 4%;

							vertical-align: top;
						}

						.right_label {
							width: 70%;
							word-break: break-all;
						}
					}
				}

				.left_label {
					margin-right: 30px;

					color: #999999;

					text-align: right;

					width: 130px;

					vertical-align: top;
				}

				.right_label {
					width: 50%;

					text-align: left;
				}

				label {
					font-weight: normal;

					display: inline-block;
				}
			}
			.moreInfo {
				border-top: 1px solid #cccccc;

				font-size: 14px;

				font-weight: bold;

				margin: 0px 15px;

				padding-top: 15px;

				position: relative;

				label {
					margin-top: 10px;
				}

				.editBtn {
					position: absolute;

					top: 8px;

					right: 0;
				}
			}
		}
	}
}
.titleIcon {
	display: inline-block;

	width: 8px !important;

	height: 20px;

	background: #498df0;

	border-radius: 5px;

	vertical-align: bottom;

	margin-right: 5px !important;
}
.avatar {
	display: inline-block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-align: center;
	color: #fff;
	background: #c0c4cc;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	height: 50px;
	width: 50px;
	line-height: 50px;
	margin-left: 5px;
	overflow: hidden;
	border-radius: 4px;
	&:hover {
		cursor: pointer;
	}
	img {
		display: block;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
</style>
<style lang="scss">
.imgInfo {
	border: none;
	.el-collapse-item__header {
		border: none;
	}
}
</style>
