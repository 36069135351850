<template>
	<div>
		<el-upload
			ref="upload"
			class="uploadBox"
			element-loading-target=".el-upload-list__item-actions"
			v-loading="upLoadLoading"
			element-loading-text="正在上传......"
			action="#"
			:file-list="fileUrlList"
			accept="image/jpeg,image/png,image/jpg"
			list-type="picture-card"
			:before-upload="beforeUpload"
			:on-remove="removeImg"
			:on-preview="handlePictureCardPreview"
			:http-request="handleUpload"
			:limit="limit"
		>
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :modal="false" :visible.sync="previewVisible">
			<div ref="imgPre">
				<img width="100%" :src="dialogImageUrl" alt="" />
			</div>
		</el-dialog>
		<!-- <el-progress type="circle" :percentage="percentage"></el-progress> -->
		<div class="tipBox">
			<span class="tips">仅限jpg,jpeg,png格式<br />图片大小不超过{{ maxSize }}</span>
		</div>
	</div>
</template>

<script>
import * as compress from '@/utils/compress';
import { waterMark } from '@/utils/util';
export default {
	name: 'components-upload',
	props: {
		value: { type: [Array, String] },
		limit: { type: Number },
		img2base64: { type: Boolean }
	},
	watch: {
		value(val) {
			if (!val) {
				this.fileUrlList = [];
				this.imageList = [];
			}
		}
	},
	computed: {
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		},
		maxSize() {
			if (this.img2base64) {
				return '20KB';
			}
			return '2M';
		}
	},
	data() {
		return { upLoadLoading: false, dialogImageUrl: '', previewVisible: false, imageList: [], fileUrlList: [] };
	},

	mounted() {
		if (this.value?.length) {
			this.fileUrlList = this.value;
			this.imageList = this.fileUrlList;
		}
	},

	methods: {
		blob2DataUrl(blob, cb) {
			const reader = new FileReader();

			reader.onload = (e) => {
				const base64 = e.target.result;

				cb(base64);
			};
			reader.readAsDataURL(blob);
		},
		handleUpload(eve) {
			this.uploadDone = false;
			this.upLoadLoading = true;

			if (this.img2base64) {
				// const url = URL.createObjectURL(eve.file);

				this.blob2DataUrl(eve.file, (base64) => {
					this.uploadDone = true;
					this.upLoadLoading = false;
					this.imageList.push({ name: eve.file.name, url: base64 });
					this.changValue(this.imageList);
					this.dialogImageUrl = base64;
				});
				return;
			}
			const formData = new FormData();

			formData.append('imgFile', eve.file);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.$axios
				.post('/v1/common/upload/file', formData, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					this.uploadDone = true;
					this.upLoadLoading = false;
					// this.$message.success('上传成功');
					this.imageList.push({ name: eve.file.name, size: '', url: res.httpUrl, bucketName: res.bucketName, key: res.key });
					this.changValue(this.imageList);
					this.dialogImageUrl = res.httpUrl;
				});
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.previewVisible = true;
			this.$nextTick(() => {
				// 加水印
				waterMark({ text: this.userInfo.nickName, container: this.$refs.imgPre });
			});
		},
		beforeUpload(file) {
			return new Promise((resolve, reject) => {
				const isLt2M = file.size / 1024 / 1024 < 2; // 判定图片大小是否小于2MB
				const isLt2K = file.size / 1024 <= 20; // 判定图片大小是否小于20 kb

				if (!isLt2M) {
					this.$message.error('大小不得超过2M');
					reject();
				}
				if (!isLt2K && this.img2base64) {
					this.$message.error('大小不得超过20KB');
					reject();
				}
				const image = new Image();

				let resultBlob = '';

				image.src = URL.createObjectURL(file);
				image.onload = () => {
					// 压缩图片
					resultBlob = compress.onImageDrop(image);
					resolve(resultBlob);
				};
				image.onerror = () => {
					reject();
				};
			});
		},
		removeImg(file) {
			// 编辑状态下
			if (this.fileUrlList && this.fileUrlList.length > 0) {
				// eslint-disable-next-line camelcase
				this.fileUrlList.forEach((val, index, val_arr) => {
					if (val_arr[index].name == file.name) {
						this.fileUrlList.splice(index, 1);
					}
				});
			} else {
				// 新增状态下
				// eslint-disable-next-line camelcase
				this.imageList.forEach((val, index, val_arr) => {
					if (val_arr[index].name == file.name) {
						this.imageList.splice(index, 1);
					}
				});
			}
			this.changValue(this.imageList);
		},
		changValue(val) {
			this.$emit('change', val);
		}
	}
};
</script>

<style lang="scss" scoped>
.tipBox {
	width: 125px;
	line-height: normal;
	vertical-align: top;
	.tips {
		font-size: 12px;
		color: #c0c4cc;
	}
}
</style>
