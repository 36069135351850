var onImageDrop = function(image) {
    // 最大图片设定
    //base64转file对象,并进行压缩
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    // let initSize = image.src.length
    let { width } = image, { height } = image
    canvas. width = width
    canvas.height = height
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(image, 0, 0, width, height)
    
    // 进行最小压缩0.1
    let compressData = canvas.toDataURL('image/jpeg', 0.92)
    
    // 压缩后调用方法进行base64转Blob，方法写在下边
    let blobImg = dataURItoBlob(compressData)
    return blobImg

    function  dataURItoBlob(data) {
        let byteString;
        if(data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(data.split(',')[1])
        }else {
            byteString = unescape(data.split(',')[1])
        }
        let mimeString = data
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];
        let ia = new Uint8Array(byteString.length)
        for( let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], {type: mimeString})
    }
}
export {
    onImageDrop
  }